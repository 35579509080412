.moduleEditorContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  margin-left: 15vw;
  margin-right: 15vw;
  padding-top: 5vh;
}

.moduleEditorHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2vh;
}